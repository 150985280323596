import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import Container from '../components/Container'
import Layout from '../components/Layout'

export default () => {
	const data = useStaticQuery(query)

	return (
		<Layout>
			<ContortionContainer>
				<section>
					<ContortionImg fluid={ data.contortion_1.childImageSharp.fluid } alt="Urnaa contortion position one" />
					<ContortionImg fluid={ data.contortion_2.childImageSharp.fluid } alt="Urnaa contortion position two" />
					<ContortionImg fluid={ data.contortion_3.childImageSharp.fluid } alt="Urnaa contortion position three" />
					<ContortionImg fluid={ data.contortion_0.childImageSharp.fluid } alt="Contortion banner" />
				</section>
				<section>
					{/*<Intro>
						Note: Head coach Amarsanaa <Important>only</Important> offers private one hour sessions to anyone interested in learning artistic 
						Mongolian contortion. To schedule a session, send us a message through the form in the contact page.
					</Intro>*/}
					<Title>What is Contortion</Title>
					<p>
						Contortion (sometimes contortionism) is a performance art in which performers, contortionists, showcase their skills of extreme physical 
						flexibility.
						Contortion acts often accompany acrobatics, circus acts, street performers and other live performing arts. Contortion acts are typically 
						performed in front of a live audience. An act will showcase one or more artists performing a choreographed set of moves or poses, often to 
						music, which require extreme flexibility. The physical flexibility required to perform such acts greatly exceeds that of the general 
						population. It is the dramatic feats of seemingly inhuman flexibility that captivate audiences.
					</p>
					<Title>Skills</Title>
					<p>
						Many factors affect the flexibility of performers including age, genetics, stature, and adherence to rigorous physical training routines. 
						Most contortionists are generally categorized as "frontbenders" or "backbenders", depending on the direction in which their spine is most 
						flexible. Relatively few performers are equally adept at both. Skills performed by contortionists include:
					</p>
					<ul>
						<li>Frontbending skills such as folding forward at the waist with the legs straight, or placing one or both legs behind the neck or shoulders with the knees bent (called a human knot).</li>
						<li>Backbending skills such as touching one's head to one's feet, or all the way to the buttocks (called a head-seat), while standing, lying on the floor, or in a handstand. A Marinelli bend is a backbend while supported only by a grip at the top of a short post that is held in the mouth.</li>
						<li>Splits and oversplits (a split of more than 180 degrees) may be included in frontbending or backbending acts. An oversplit may be performed while the feet are supported by two chairs or by two assistants.</li>
						<li>Enterology is the practice of squeezing one's body into a small, knee-high box or other contained space which initially appears to audiences as being too small to contain the performer.</li>
						<li>Dislocations of the shoulders or hip joints are sometimes performed as a short novelty act by itself. One example is lifting the arm to the side until it passes behind the head and lies across the top of the shoulders; also referred to as bonebreaking.</li>
					</ul>
					<Title>Performing</Title>
					<p>
						Contortion acts are highly variable with many incorporating elements of humor, drama, shock, sensuality or a blend of styles. Contortion is often incorporated into other performances such as dance and theater.
					</p>
					<p>
						A contortionist may perform alone, may have one or two assistants, or up to four contortionists may perform together as a group.
					</p>
				</section>
			</ContortionContainer>
		</Layout>
	)
}

//const Intro = styled.p`
//	font-weight: 600;
//`

const ContortionContainer = styled(Container)`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 569px) {
		grid-template-columns: 1fr;
	}
` 

const Title = styled.h3`
	color: hsl(356, 45%, 48%)
`

//const Important = styled.span`
//	color: red;
//`

const ContortionImg = styled(Img)`
	margin-bottom: 20px;
	border-radius: 4px;
`

const query = graphql`
	query {
		contortion_0: file(relativePath: { eq: "contortion/contortion.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		contortion_1: file(relativePath: { eq: "contortion/contortion_1.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		contortion_2: file(relativePath: { eq: "contortion/contortion_2.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		},
		contortion_3: file(relativePath: { eq: "contortion/contortion_3.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`